import { render, staticRenderFns } from "./scheduleAward.vue?vue&type=template&id=10ffacd0&scoped=true&"
import script from "./scheduleAward.vue?vue&type=script&lang=js&"
export * from "./scheduleAward.vue?vue&type=script&lang=js&"
import style0 from "./scheduleAward.vue?vue&type=style&index=0&id=10ffacd0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10ffacd0",
  null
  
)

export default component.exports